<template>
    <div>
        <Toast position="top-right" group="tr" />
        <Toast position="bottom-right" group="br" />
    </div>
</template>

<script setup>

import {useToast} from 'primevue/usetoast'
import {onUnmounted} from 'vue'
import {usePage} from '@inertiajs/inertia-vue3'
import {Inertia} from '@inertiajs/inertia'
import Toast from 'primevue/toast'

const toast = useToast()

onUnmounted(

  Inertia.on('success', () => {

    const flash = usePage().props.value.flash;

    Object.getOwnPropertyNames(flash)
      .forEach(
        function (severity) {
          if (flash[severity] !== null) {
            toast.add({
              severity: severity,
              detail: flash[severity],
              closable: severity === 'error',
              life: (severity === 'error' ? null : 5000),
              group: 'br'
            })
          }
        }
      )
  }),

  Inertia.on('exception', (e) => {

    console.log(e.detail)

    /*toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Ooops ... something went wrong.',
      group: 'tr',
    })*/
  })
)

</script>
