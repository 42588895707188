import {computed, createApp, h, nextTick} from 'vue'
import {createInertiaApp, Link, usePage} from '@inertiajs/inertia-vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { InertiaProgress } from '@inertiajs/progress'
import {scrollToFirstError} from './handlers'
import * as Sentry from "@sentry/vue"
import { i18nVue } from 'laravel-vue-i18n'

import 'primeflex/primeflex.css'
import 'primevue/resources/primevue.min.css'
import '../sass/app.scss'

import PrimeVue from 'primevue/config'

import AppLayout from './Layouts/AppLayout.vue'

import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

import { modal } from "momentum-modal"

import User from './user'

//Vue.config.devtools = true

createInertiaApp({

  resolve: name => {

    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')).then(page => {

      page.default.layout = AppLayout

      return page
    })
  },

  setup({el, App, props, plugin}) {

    // Create app.
    const app = createApp({
      render: () => h(App, props),
    })

    // Translations.
    app.use(i18nVue, {
      lang: import.meta.env.VITE_APP_LANG ?? 'es',
      fallbackLang: 'de',
      resolve: async lang => {
        const langs = import.meta.glob('../../lang/*.json');
        return await langs[`../../lang/${lang}.json`]();
      }
    })

    // Modal.
    app.use(modal, {
      resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
    })

    // Plugins.
    app.use(plugin)
    app.use(PrimeVue)
    app.use(ConfirmationService)
    app.use(ToastService)


    // Components.
    app.component('Link', Link)
    app.component('router-link', {}) // Workaround for vue router dependency: https://github.com/primefaces/primevue/issues/1231

    // Directives.
    app.directive('autofocus', {
      async mounted(el) {
        await nextTick(() => el.focus())
      }
    })

    app.directive('styleclass', StyleClass)
    app.directive('tooltip', Tooltip)

    // Global Provides.
    app.provide('user', computed(() => new User(usePage().props.value.auth.user)))
    app.provide('modules', computed(() => usePage().props.value.modules))
    app.provide('demo', computed(() => usePage().props.value.demo))

    // Sentry error tracking.
    Sentry.init({
      app,
      dsn: "https://10cbebe4461b479985968925772e1f5b@sentry.smartcode.ch/16",
      logErrors: true,
      trackComponents: true,
      tracesSampleRate: 1.0,
      denyUrls: [new RegExp(/.*\.test.*/)],

      beforeSend(event) {

        if (import.meta.env.VITE_SENTRY_REPORT === 'false') {
          return null;
        }

        event.user =  usePage().props.value.auth?.user;

        return event;
      },
    });

    // Mount the app.
    app.mount(el)

      // Global event listener.
      .$inertia.on('error', scrollToFirstError)

    // Gloabl properties.
    app.config.globalProperties.window = window

  },

})

InertiaProgress.init({

  // The delay after which the progress bar will
  // appear during navigation, in milliseconds.
  delay: 300,

  // The color of the progress bar.
  color: '#FF36E2',

  // Whether to include the default NProgress styles.
  includeCSS: true,

  // Whether the NProgress spinner will be shown.
  showSpinner: false,
})
